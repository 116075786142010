@import-normalize;

html { font-size: 62.5%; } 

body {
  min-height: 100vh;
}

html,
body {
  background: black;
}

:root {
  --NavigationHeight: 40px;
  --fontSizeXS: 1rem;
  --fontSizeS: 1.2rem;
  --fontSizeM: 1.4rem;
  --fontSizeL: 1.8rem;
  --fontSizeXL: 2.4rem;
  --fontSizeXXL: 3.2rem;
  --lintHeightS: 1.4rem;
  --lintHeightL: 1.8rem;
  --lineHeightPara: 24px;
  --marginMedium: 24px;
  --marginLarge: 32px;
  --vh: 0px;
}

@media screen and (min-width: 768px) {
  :root {
    --NavigationHeight: 100px;
    --marginMedium: 32px;
    --marginLarge: 48px;
  }
}


* {
  box-sizing: border-box;
}

img {
  user-select: none!important;
}

button {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  padding: 0;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
  transition-delay: 300;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
}

p,
li,
a {
  font-size: var(--fontSizeS);
  line-height: var(--lineHeightPara);
  color: white;
}

.page-wrapper-padding {
  padding: 150px 16px 48px 16px;
}
.tattoo-wrapper-padding {
  padding: 150px 16px 48px 16px;
}

.shop-wrapper-padding {
  padding: 150px 16px 16px 16px;
}
@media screen and (min-width: 768px) {
  .shop-wrapper-padding {
    padding: 0 16px 16px 16px;
  }
  .page-wrapper-padding {
    padding: 150px 16px 124px 16px;
  }
  
}


.video-wrapper {
  position: relative;
  /* margin-right: 50vw; */
}

.video-wrapper:nth-child(1) {
  /* margin-left: 16px; */
}

.image-index-container {
  /* opacity: 0;
  transition: opacity 0.6s ease-in-out; */
}

.video-container {
  width: calc(100vw - 32px);
  margin: 0 auto;
}

.video-container video {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .video-container {
    min-width: 500px;
    width: 50vw;
    margin-right: 50vw;
  }
  .video-wrapper:nth-child(1) {
    margin-left: calc(50vw - 25vw); /* minus half with width of an video */
  }
  .video-wrapper:last-child {
    /* margin: 0 25vw 0 25vw !important; */
    margin-right: calc(50vw - 50vw)!important;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: black;
}

.footer ul {
  display: flex;
  gap: 24px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  font-size: var(--fontSizeXS);
  cursor: pointer;
  margin-left: 24px;
}