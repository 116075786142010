.navigation {
  width: 100%;
  user-select: none;
  position: relative;
  background: #1e1e1e;
  position: fixed;
  height: 90px;
  z-index: 100;
}

.navigation.showSubMenu {
  background: black;
  height: 324px;
}

.logo {
  width: 170px;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 16px; */
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .navigation {
    background: transparent;
    position: relative;
    height: auto;
    z-index: 100;
    background: black;
  }
  /* .navigation.fixed {
    position: fixed;
  } */
}

.textLogo {
  color: white;
  font-size: 19px;
  letter-spacing: 2px;
  line-height: 20px;
  position: absolute;
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-100%);
  cursor: pointer;
}

.backButton {
  position: absolute;
  left: 16px;
  top: 16px;
  color: white;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.backButton p {
  font-size: var(--fontSizeS);
}

.backButtonIcon {
  width: 25px;
  margin-left: -4px;
  margin-right: 16px;
}

.bottomLogo {
  font-size: var(--fontSizeL);
}

.menuMobile {
  color: white;
  text-align: right;
  font-size: var(--fontSizeM);
  position: absolute;
  right: 16px;
  top: 28px;
  /* border: 1px solid white; */
  /* padding: 8px 12px; */
}

.backButtonMobile {
  left: 16px;
  right: auto;
}

.menuLinksPages {
  text-align: right;
  position: fixed;
  right: 0;
  top: 0;
  background: black;
  z-index: 1;
  padding: 16px;
  width: 100%;
  margin-top: 0;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
}

.list {
  margin-top: 32px;
}

.list ul {
  padding: 0 8px 8px 16px;
  background-color: black;
}

.menuLink:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.menuLink {
  font-size: var(--fontSizeM);
  line-height: 46px;
  color: white;
  font-weight: 700;
}

.menuLinkPrimary:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.menuLinkPrimary {
  font-size: var(--fontSizeXXL);
  line-height: 46px;
  color: white;
  font-weight: 700;
  user-select: none;
}

.biSubdirectoryLeftIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.subLink {
  font-size: var(--fontSizeM);
  line-height: 32px;
}


@media screen and (min-width: 768px) {
  .menuMobile {
    display: none;
  }
  .menuLinksPages {
    padding: 0;
    right: 16px;
    width: auto;
    height: auto;
    background: transparent;
    overflow: auto;
  }
  .menuLink {
    font-size: var(--fontSizeS);
    line-height: 20px;
  }
  .menuLinkPrimary {
    font-size: var(--fontSizeS);
    line-height: 20px;
  }
  .subLink {
    font-size: var(--fontSizeXS);
    line-height: 24px;
    font-weight: 400;
  }
  .list {
    margin-top: 16px;
  }
}

.forwardButtonIcon {
  width: 25px;
  transform: scale(-1);
  margin-left: 8px;
  transition: margin-left 0.2s ease-in-out;
}

.leftAlignedMenuWrapper {
  display: none;
}

.leftAlignedMenuWrapper.showSecondaryNav {
  display: block;
  margin: 32px 16px;
}

.leftAlignedMenuWrapper.showSecondaryNav h2 {
  font-size: var(--fontSizeM);
  line-height: 24px;
  cursor: pointer;
  position: relative;
}
.forwardButtonIcon {
  width: 25px;
  position: absolute;
  transform: scale(-1);
  margin-left: 8px;
  transition: margin-left 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
  .leftAlignedMenuWrapper {
    display: block;
    position: fixed;
    bottom: 16px;
    left: 16px;
  }
  .leftAlignedMenuWrapper.showSecondaryNav {
    display: none;
  }
  .leftAlignedMenuWrapper h2 {
    margin-bottom: 4px;
    cursor: pointer;
  }
  .forwardButtonIcon {
    top: -3px;
  }
  .leftAlignedMenuWrapper h2:last-child {
    margin-bottom: 0;
  }

  .leftAlignedMenuWrapper h2:hover .forwardButtonIcon {
    margin-left: 16px;
  }

  .secondaryNavigationWrapper {
    position: relative;
  }
}
