.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;
}

.quote {
  color: white;
}

.quoteWrapper {
  margin-bottom: 32px;
}

.wrapper .image {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    /* margin: 0 16px; */
  }
  .wrapper .image {
    width: 100%;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 1400px) {
  .wrapper {
    margin: 0 auto;
  }
}

.image:hover {
  cursor: pointer;
}
