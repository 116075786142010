.sidebarTitle {
  font-size: var(--fontSizeM);
  letter-spacing: 1px;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  color: white;
  z-index: 1;
}

.price {
  margin-top: 0;
  font-size: var(--fontSizeM);
  letter-spacing: 1px;
  text-align: right;
  color: white;
  font-weight: 400;
}

.moreInfo {
  margin-top: 16px;
  font-size: var(--fontSizeS);
  text-decoration: underline;
  letter-spacing: 1px;
  text-align: right;
  color: white;
}

.sidebarContainer {
  display: none;
}

@media screen and (min-width: 768px) {
  .sidebarContainer {
    width: 30vw;
    max-width: 25vw;
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 20px;
    padding: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
  }
  .sidebarWrapper {
    height: 100vh;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sidebarTitle {
    margin: 8px 0 16px 0;
  }
  .price {
    user-select: none;
  }
}
