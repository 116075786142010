.horizontalContainer {
  display: flex;
  flex-direction: column;
  gap: 96px;
}

.imageContainer {
  margin: 0 16px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.imageContainer img {
  width: 100%;
}

.moreInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 0;
  bottom: -24px;
}

.moreDetails {
  color: white;
  text-align: right;
  font-size: var(--fontSizeS);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  /* html,
  body {
    overflow: hidden!important;
    height: 100%;
  } */

  .horizontalContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
    height: 100vh;
    overflow: scroll;
  }

  .horizontalContainer::-webkit-scrollbar {
    display: none;
  }

  .imageContainer {
    margin: 0 33vw;
  }

  .imageContainer:first-child {
    margin: 0 33vw 0 calc(50vw - 200px); /* NEGATIVE 200 TO CENTER THE FIRST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }
  .imageContainer:last-child {
    margin: 0 calc(50vw - 200px) 0 25vw; /* NEGATIVE 200 TO CENTER THE LAST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .pastelSet:first-child {
    margin: 0 33vw 0 calc(50vw - 335px) !important; /* NEGATIVE 200 TO CENTER THE FIRST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .pastelSet:last-child {
    margin: 0 calc(50vw - 335px) 0 25vw; /* NEGATIVE 200 TO CENTER THE LAST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .detailedPenAndInk:first-child {
    margin: 0 33vw 0 calc(50vw - 194px) !important; /* NEGATIVE 200 TO CENTER THE FIRST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .detailedPenAndInk:last-child {
    margin: 0 calc(50vw - 359px) 0 25vw; /* NEGATIVE 200 TO CENTER THE LAST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .funny:first-child {
    margin: 0 33vw 0 calc(50vw - 200px) !important; /* NEGATIVE 200 TO CENTER THE FIRST IMAGE (IMAGE NEEDS TO BE 400 WIDTH FOR THIS TO WORK) */
  }

  .imageContainer img {
    width: auto;
    height: 70vh;
    max-height: 550px;
  }

  .moreDetails:hover {
    text-decoration: underline;
  }
}

.glitch {
  font-size: 8rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;

  text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

  animation: glitch 500ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 650ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-0.025em, -0.0125em);
  /* color: green; */
  opacity: 0.8;
}

.glitch span:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  transform: translate(0.0125em, 0.025em);
  /* color: red; */
  opacity: 0.8;
}

/* https://web.dev/prefers-reduced-motion/#(bonus)-forcing-reduced-motion-on-all-websites */

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .center {
    height: 100vh;
    overflow: hidden;
  }
}