.videoTransWrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.transcript {
  margin: 32px 0;
}

.transcript p {
  color: white;
}
.transcript h2 {
  color: white;
  font-size: var(--fontSizeM);
  text-transform: uppercase;
  margin-bottom: 16px;
  line-height: 2.4rem;
}

@media screen and (min-width: 768px) {
  .transcript {
    margin: 32px 0 0 0;
  }
}

.title {
  color: white;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-size: var(--fontSizeXXL);
  line-height: 3.8rem;
}

.textContainer {
  height: 180px;
  overflow: hidden;
}

.textContainer p {
  margin-bottom: 16px;
}

.textContainer p:last-child {
  margin-bottom: 0;
}

.readMore {
  text-decoration: underline;
  margin-top: 8px;
}

.expand {
  height: auto;
}

.marginTop {
  margin-top: 49px;
}

@media screen and (min-width: 768px) {
  .tattooWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  .mobile {
    display: none;
  }
  .textContainer {
    height: auto;
  }
  .readMore {
    display: none;
  }
}
 
.tattooWrapper .left {
  box-sizing: border-box;
}

.tattooWrapper .right {
  /* margin-left: auto; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 16px; */
  /* margin-bottom: 16px; */
}

.hmm {
  /* margin-top: 32px; */
}

.right {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px; */
}

.buttonWrapper {
  display: flex;
  gap: 16px;
}

@media screen and (min-width: 768px) {
  .tattooWrapper .left {
    height: calc(100vh - 168px); /* minus height of navigation */
    overflow-y: scroll;
  }
  .tattooWrapper .right {
    /* grid-template-columns: 1fr 1fr 1fr; */
  }
  .hmm {
    height: calc(100vh - 168px);
    overflow: scroll;
    margin-top: 0;
  }
}

.video {
  width: 100%;
  margin-bottom: 16px;
}

.contactList {
  margin: 16px 0 0 16px;
}

.contactList h1 {
  margin-bottom: 16px;
  color: white;
  text-transform: uppercase;
}

.contactList ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.contactList ul li {
  line-height: 38px;
}

.contactList ul li a {
  font-size: var(--fontSizeXL);
  font-weight: 700;
  color: white;
  text-decoration: none;
}

.contactList ul li a:hover {
  text-decoration: underline;
}

.image {
  width: 100%;
  margin-right: 16px;
}

@media screen and (min-width: 768px) {
}
